export default function toRinggit(amount = 0) {
  let formattedAmount = amount.toLocaleString('en-MY', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (amount < 0 && formattedAmount.includes('.')) {
    const parts = formattedAmount.split('.');
    const integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    formattedAmount = integerPart + '.' + parts[1];
  }

  return 'RM' + formattedAmount;
}

export function currency(amount = 0) {
  let formattedAmount = amount.toLocaleString('en-MY');
  return formattedAmount;
}
