import SectionTitle from "../SectionTitle";
// mock data
import { mockStarted } from "../../mock/mockData";
import { useTranslation } from "react-i18next";

const HomeStarted = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto max-w-screen-xl px-5 mt-10">
      <SectionTitle>{t("home.section4.title")}</SectionTitle>
      <div className="flex flex-col lg:flex-row my-10 gap-16 lg:gap-4">
        {mockStarted.map((item, i) => (
          <div className="lg:w-1/3 flex flex-col items-center" key={i}>
            <div className="flex items-center justify-center gap-2 mb-5">
              <span className="flex items-center justify-center w-8 h-8 rounded-full bg-[#e8fff3] text-green-500 text-md lg:text-lg font-semibold">
                {i + 1}
              </span>
              <h5 className="text-md lg:text-lg font-semibold">
                {t(`home.section4.step${i + 1}.title`)}
              </h5>
            </div>
            <p className="text-sm lg:text-lg text-gray-500 opacity-75 text-center">
              {t(`home.section4.step${i + 1}.subtitle`)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeStarted;
