import { useTranslation } from "react-i18next";
import SectionTitle from "../SectionTitle";
import { WaveTop, WaveBottom } from "../Wave";

const HomeBuild = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-20">
      <div className="relative">
        <WaveTop bgName={process.env.REACT_APP_BG_COLOR} />
      </div>
      {/* <div className="bg-[#0e0e42] py-20"> */}
      <div className="bg-blue-scfx py-20">
        <div className="container mx-auto max-w-screen-xl px-5">
          <SectionTitle className="text-white">
            {t("home.section3.title")}
          </SectionTitle>
          <div className="flex flex-col lg:flex-row justify-center gap-10 lg:gap-16 my-10 lg:my-20">
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-2 w-60 h-60 rounded-full border border-white">
                <h1 className="text-white text-2xl lg:text-3xl font-semibold">
                  $1,100,000
                </h1>
                <p className="text-gray-500 text-sm lg:text-md font-medium">
                  {t("home.section3.Withdrawneachmonth")}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-2 w-60 h-60 rounded-full border border-white">
                <h1 className="text-white text-2xl lg:text-3xl font-semibold">
                  $30,000,000
                </h1>
                <p className="text-gray-500 text-sm lg:text-md font-medium">
                  {t("home.section3.Monthlyinvestingvolume")}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-2 w-60 h-60 rounded-full border border-white">
                <h1 className="text-white text-2xl lg:text-3xl font-semibold">
                  15,000+
                </h1>
                <p className="text-gray-500 text-sm lg:text-md font-medium">
                  {t("home.section3.Activeinvestorsdaily")}
                </p>
              </div>
            </div>
          </div>
          <h1 className="text-gray-400 text-md lg:text-xl font-semibold text-center">
            "{t("home.section3.subtitle")}"
          </h1>
          <p className="text-gray-400 text-sm lg:text-lg font-semibold text-center mt-2">
            {t("home.section3.endsection")}
          </p>
        </div>
      </div>
      <div className="relative">
        <WaveBottom bgName={process.env.REACT_APP_BG_COLOR} />
      </div>
    </div>
  );
};

export default HomeBuild;
