import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import home_en from "./pages/translation/en/Home.json";
import home_my from "./pages/translation/my/Home.json";
import home_cn from "./pages/translation/cn/Home.json";
import home_ja from "./pages/translation/ja/Home.json";
import member_en from "./pages/translation/en/Member.json";
import member_cn from "./pages/translation/cn/Member.json";
import member_my from "./pages/translation/my/Member.json";
import member_ja from "./pages/translation/ja/Member.json";

i18next.use(initReactI18next).init({
  debug: false,
  fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
  lng: process.env.REACT_APP_DEFAULT_LANGUAGE,
  supportedLngs: ["en", "my", "cn", "ja"],
  resources: {
    en: {
      translation: {
        home: home_en,
        member: member_en
      },
    },
    my: {
      translation: {
        home: home_my,
        member: member_my
      },
    },
    cn: {
      translation: {
        home: home_cn,
        member: member_cn
      },
    },
    ja: {
      translation: {
        home: home_ja,
        member: member_ja
      }
    }
  },
});


export default i18next;
