import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import useNewsContent from "../hooks/zustand/useNewsContent";

export default function News() {
  const { t, i18n } = useTranslation();
  const useNewsStore = useNewsContent();
  const [newsList, getNewsList] = useState(null);
  const getNews = async () => {
    try {
      const newsData = await useNewsStore.getNews(i18n.language);
      const newsState = newsData;
      getNewsList(newsState);
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getNews();
  }, [i18n.language]);
  return (
    <>
      <Layout changeColorWhileScroll={true}>
        <div className='w-full h-full py-16' style={{ minHeight: 'calc(100vh - 15rem)' }}>
          <div className='relative w-full h-full z-10 max-w-5xl px-5 mx-auto'>
            <div className='grid grid-cols-1 gap-5'>
              <h1 className='text-black font-bold text-xl sm:text-3xl text-center'>
                {t('home.footer.News')}
              </h1>
            </div>
            <div className="h-5"></div>
            <div>
              {newsList && newsList.items.length > 0 && newsList.items.map((item) => {
                return (
                  <>
                    <a href={newsList.baseUrl + item.storyPath} target="_blank" >
                      <div className="flex flex-col w-full border rounded-3xl p-5 cursor-pointer bg-gray-50">
                        <div className="py-3 font-semibold text-xl ">
                          {item.title}
                        </div>
                        <div className="text-xs" >
                          Published Date : {new Date(item.published * 1000).toLocaleString('en-US', { timeZone: 'UTC' })}
                        </div>
                        <div className="text-xs" >
                          Provider : {item.provider}
                        </div>
                      </div>
                      <div className="h-5"></div>
                    </a>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>

    </>
  );
}