import { memo, forwardRef, useId, useRef } from 'react';

const InputText = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const handleLabelClick = () => {
    // Fokuskan input ketika label diklik
    inputRef.current.focus();
  };

  return (
    <div className='relative'>
      <input
        ref={(inputElement) => {
          // Simpan ref input ke inputRef dan juga meneruskan ref ke prop
          inputRef.current = inputElement;
          if (typeof ref === 'function') {
            ref(inputElement);
          } else if (ref) {
            ref.current = inputElement;
          }
        }}
        type={props.type}
        id={`floating${useId()}`}
        className='block rounded-lg px-2.5 pb-2.5 pt-5 font-semibold w-full text-sm text-secondary bg-white appearance-none focus:outline-none focus:ring-0 peer border-low border-card-secondary/50'
        placeholder=' '
        required={props.required}
        {...props}
      />
      <label
        htmlFor={`floating${useId()}`} // Gunakan id yang sesuai dengan input
        className='absolute text-sm text-secondary duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 font-semibold cursor-pointer'
        onClick={handleLabelClick} // Tambahkan event handler untuk klik label
      >
        {props.label}{' '}
        {props.required ? <span className='text-red-500'>*</span> : null}
      </label>
      {props.error ? (
        <small className='text-red-500'>{props.error}</small>
      ) : null}
    </div>
  );
});

export default memo(InputText);
