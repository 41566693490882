export default function TabNavigationDashboard({
  tabSelected,
  setTabSelected,
  tabNum,
  label,
}) {
  return (
    <li role='presentation'>
      <button
        className={`text-black font-semibold mx-3 px-5 py-2 ${tabSelected.currentTab === tabNum
            ? 'border-b-4 border-[#3B82F6]'
            : 'border-b-4 border-transparent'
          } duration-300 transition-colors`}
        id='tab-label-1a'
        role='tab'
        tabIndex={`${tabSelected.currentTab === tabNum ? '0' : '-1'}`}
        aria-selected={tabSelected.currentTab === tabNum ? true : false}
        onClick={() => setTabSelected({ ...tabSelected, currentTab: tabNum })}
        type='button'
      >
        {label}
      </button>
    </li>
  );
}
