import { create } from 'zustand';
import { Api } from '../../../lib/api/axios';
import useAdminAuth from './useAdminAuth';

const useAdminStore = create((set, get) => ({
  showAlert: false,
  setShowAlert: (data = false) => set({ showAlert: data }),
  contentAlert: '',
  setContentAlert: (data) => set({ contentAlert: data }),
  data: [],
  totalData: 0,
  getData: async (
    searchTerm = '',
    page = 1,
    perPage = 10,
    sort = '',
    sortType = ''
  ) => {
    try {
      const res = await Api.get(
        `/cms/admin?handler=${
          useAdminAuth.getState().admin?.role === 'master' ||
          useAdminAuth.getState().admin?.role === 'admin'
            ? ''
            : useAdminAuth.getState().admin?._id
        }&searchTerm=${searchTerm}&page=${page}&limit=${perPage}&sort=${sort}&sortType=${sortType}`
      );
      set(() => ({
        data: res.data?.docs,
        totalData: res.data?.totalDocs,
      }));
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  getDataByAdminId: async (adminId) => {
    try{
      const res = await Api.get(`/cms/admin/adminId/${adminId}` );
      return res.data;
    }
    catch(error){
      console.log(error);
    }
  }, 
  getDataById: async (id) => {
    try{
      const res = await Api.get(`/cms/admin/${id}` );
      return res.data;
    }
    catch(error){
      console.log(error);
    }
  }, 
  getUplinerData: async(role) => {
    try{
      const res = await Api.get(`/cms/admin-upliner?role=${role}`);
      return res.data;
    }
    catch(error){
      return error?.response?.data;
    }
  },
  createData: async (payload) => {
    try {
      const { status } = await Api.post('/cms/admin', payload);
      await get().getData(
        useAdminAuth.getState().admin?.role === 'master' ||
          useAdminAuth.getState().admin?.role === 'admin'
          ? ''
          : useAdminAuth.getState().admin?._id
      );
      return status;
    } catch (error) {
      return error.response?.data;
    }
  },
  updateData: async (id, payload, controller) => {
    try {
      const { status } = await Api.patch(`/cms/admin/${id}`, payload);
      await get().getData(
        useAdminAuth.getState().admin?.role === 'master' ||
          useAdminAuth.getState().admin?.role === 'admin'
          ? ''
          : useAdminAuth.getState().admin?._id,
        controller?.search,
        controller?.page,
        controller?.perPage
      );
      return status;
    } catch (error) {
      return error.response?.data;
    }
  },
  resetReferral: async (id, controller) => {
    try {
      const res = await Api.post(`/cms/admin/reset-referral/${id}`);
      await get().getData(
        controller?.search,
        controller?.page,
        controller?.perPage
      );
      return res;
    } catch (error) {
      return error.response?.data;
    }
  },
  resetPassword: async (id, controller) => {
    try {
      const res = await Api.post(`/cms/admin/reset-password/${id}`);
      await get().getData(
        controller?.search,
        controller?.page,
        controller?.perPage
      );
      return res;
    } catch (error) {
      return error.response?.data;
    }
  },
  unlockAdmin: async (id, controller) => {
    try {
      const res = await Api.post(`/cms/admin/unlock/${id}`);
      await get().getData(
        controller?.search,
        controller?.page,
        controller?.perPage
      );
      return res;
    } catch (error) {
      return error.response?.data;
    }
  },
  deleteData: async (id, controller) => {
    try {
      const { status } = await Api.delete(`/cms/admin/${id}`);
      await get().getData(
        controller?.search,
        controller?.page,
        controller?.perPage
      );
      return status;
    } catch (error) {
      return error.response?.data;
    }
  },
}));

export default useAdminStore;
