import { HiCheckCircle } from 'react-icons/hi';

export default function BadgeActive() {
  return (
    <span className='bg-c-green h-max py-1 px-2 rounded-full flex items-center justify-center space-x-1'>
      <span className='capitalize text-xs'>Online</span>
      <HiCheckCircle />
    </span>
  );
}
