import React, { useEffect, useState } from 'react';
import SendBirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import ChannelList from '@sendbird/uikit-react/ChannelList';
import Channel from '@sendbird/uikit-react/Channel';
import OpenChannelList from '@sendbird/uikit-react/OpenChannelList';
import '@sendbird/uikit-react/dist/index.css';
import OpenChannel from '@sendbird/uikit-react/OpenChannel';
import OpenChannelSetting from '@sendbird/uikit-react/OpenChannelSettings';
import App from '@sendbird/uikit-react/App';
import usePersonalInformation from '../../hooks/zustand/usePersonalInformation';
import { useParams } from 'react-router-dom';
import SendBirdApi from '../../lib/api/sendBirdApi';
import useAdminStore from '../../hooks/zustand/admin/useAdminStore';
import useMemberListing from '../../hooks/zustand/member/useMemberListing';
import { shallow } from 'zustand/shallow';

const PANELS = {
  CHANNEL_LIST: 'CHANNEL_LIST',
  CHANNEL: 'CHANNEL',
  CHANNEL_SETTINGS: 'CHANNEL_SETTINGS',
  MESSAGE_SEARCH: 'MESSAGE_SEARCH',
}

export default function InAppChat () {
  const params = useParams();
  const [showAllChannels, setShowAllChannels] = useState(true);
  const [panel, setPanel] = useState(PANELS.CHANNEL_LIST);
  const [currentChannelUrl, setCurrentChannelUrl] = useState(null);
  const userInfo = usePersonalInformation(
    (state) => ({
      user: state.user,
      updateData: state.updateData,
    }),
    shallow
  );
  const adminStore = useAdminStore();
  const memberListing = useMemberListing();
  
  useEffect( ()=>{
    const fetchData = async() => {
      try{
          
          if (userInfo.user?._id !== ''){
            let admin;
            
            if(userInfo.user?.admin??'' === ""){
              admin = await adminStore.getDataByAdminId("FAS");
              console.log("admin", admin);
              userInfo.user.admin = admin.map((item)=>{return item._id});
            }
            else
            {
              admin = await adminStore.getDataById(userInfo.user?.admin);
            }
            console.log("userInfo", userInfo);
            console.log("admin", admin);
            console.log("isSendBird", !userInfo?.user?.isSendbirdUser);
            if (!userInfo?.user?.isSendbirdUser){
              const payloadUser = {
                user_id: userInfo?.user?.phoneNumber.toString(),
                nickname: userInfo?.user?.firstName,
                profile_url: "https://sendbird.com/main/img/profiles/profile_05_512px.png",
                issue_access_token: true,
                metadata:{
                  font_preference: "times new roman",
                  font_color: "black"
                }
              }
              console.log("payloadMember", payloadUser);
              const respUser =  await SendBirdApi("users", payloadUser);
              userInfo.user.isSendbirdUser = true;
              userInfo.user.sbAccessToken = respUser.access_token;
              if (admin.length > 0){
                if (!admin?.isSendbirdUser){
                  const payloadAdmin = {
                    user_id: admin[0]?.adminId,
                    nickname: admin[0]?.name,
                    profile_url: "https://sendbird.com/main/img/profiles/profile_05_512px.png",
                    issue_access_token: true,
                    metadata:{
                      font_preference: "times new roman",
                      font_color: "black"
                    }
                  }
                  const respAdmin = await SendBirdApi("users", payloadAdmin);
                  if (respAdmin?.error?? false !== true){
                    admin[0].isSendbirdUser = true;
                    admin[0].sbAccessToken = respAdmin.access_token;
                  }
                }
    
                const payload = {
                  name: userInfo?.user?.phoneNumber + "_" + admin[0].adminId ,
                  channelUrl: "private_chat_"+userInfo?.user?.phoneNumber,
                  cover_url: "https://sendbird.com/main/img/cover/cover_08.jpg",
                  is_distinct: true,
                  user_ids: [userInfo?.user?.phoneNumber.toString() , admin[0].adminId],
                  operator_ids:[userInfo?.user?.phoneNumber.toString() ,admin[0].adminId]
                }
    
                const respGroup = await SendBirdApi("group_channels", payload);
                if (respGroup?.error !== true){
                  userInfo.user.sendBirdChannelId = respGroup?.channel_url;
                  admin[0].sendBirdChannelId = respGroup?.channelUrl;
                  const payloadMsg = {
                    message_type: "MESG",
                    user_id: admin[0]?.adminId,
                    message: "Good day to you. I am your assistant. Ask my anything about the investment plan. ",
                    mention_type: "users", 
                    mentioned_user_ids: [userInfo?.user?.phoneNumber]
                  };
                  await SendBirdApi(`group_channels/${respGroup?.channel_url}/messages`, payloadMsg);
                }
    
                const payloadAdmins = {
                    _id: admin[0]._id, 
                    isSendbirdUser: admin[0].isSendbirdUser,
                    sendBirdChannelId: admin[0].sbAccessToken,
                }
                await adminStore.updateData(admin[0]._id, payloadAdmins, null);
    
              }
              userInfo.user.password = userInfo.user.displayPassword;
              console.log("member.userId", userInfo.user)
              const payloadMember = {
                  member:userInfo?.user
              }
              
              await memberListing.updateMember(payloadMember, userInfo?.user._id, null );
    
            }
            else{
              setCurrentChannelUrl(userInfo?.user?.sendBirdChannelId);
            }
          }
    }
    catch(err){
      console.log(err);
    }
    }
    fetchData();
    setShowAllChannels(true);    
  },[userInfo.user]);

  return (
    <>
     <style>
          {`
              .sendbird-channel-header__right-icon {
                  visibility: hidden; /* Hide the send message icon */
                  pointer-events: none;
              }
          `}
      </style>
    <div className=''>
        <App
        replyType="THREAD"
        appId={process.env.REACT_APP_SENDBIRD_APP_ID}
        userId={userInfo?.user?.phoneNumber}
        channelUrl={currentChannelUrl}
        accessToken={userInfo?.user?.sbAccessToken}
        nickname={userInfo?.user?.fullName}
        theme="light"
        isMentionEnabled="true"
        isMultipleFilesMessageEnabled="true"
        isMessageReceiptStatusEnabledOnChannelList="true"
        imageCompression={{
          compressionRate: 0.5,
          resizingWidth: 100,
          resizingHeight: '100px',
        }}
        disableAutoSelect="true"
        disableUserProfile="true"
        config={{ logLevel: 'all' }}
        breakpoint = {/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)}
        showSearchIcon="false"
      >
      </App>      
   </div>
    </>
  );

}