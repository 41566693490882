import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import { mockAwards } from "../mock/mockData";


export default function Awards() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Layout changeColorWhileScroll={true}>
        <div className='w-full h-full py-16' style={{ minHeight: 'calc(100vh - 15rem)' }}>
          <div className='relative w-full h-full z-10 max-w-5xl px-5 mx-auto'>
            <div className='grid grid-cols-1 gap-5'>
              <h1 className='text-black font-bold text-xl sm:text-3xl text-center'>
                {t('home.awards.title')}
              </h1>
            </div>
            <div className="h-5"></div>
            <div>
              <div className="grid grid-cols-3 " >
                { mockAwards.map((item, i) => {
                  return (
                    <>
                      <div className="border-2">
                      <div>
                        <img src={ t(`home.awards.details.${i+1}.imgUrl`)} />
                      </div>
                      </div>
                      <div className="border-2 col-span-2">
                        <div className="p-10" >
                          <div className="text-4xl font-bold ">
                            { t(`home.awards.details.${i+1}.title`)}
                          </div>
                          <div className="text-sm py-6">
                          { t(`home.awards.details.${i+1}.subtitle`)}
                          </div>
                          <div className="border-b-2"></div>
                          <div className="py-6">
                            <p>
                            { t(`home.awards.details.${i+1}.detail`)}
                            </p>
                          </div>
                        </div>
                        
                      </div>
                    </>
                  );
                }) }
              </div>
            </div>
          </div>
        </div>
      </Layout>

    </>
  );
}