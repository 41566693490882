import React, { useState } from 'react';
import InputText from './dashboard/input/InputText';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Button from './dashboard/button/Button';
import { enqueueSnackbar } from 'notistack';
import useMemberListing from '../hooks/zustand/member/useMemberListing';
import App from '@sendbird/uikit-react/App';
import SendBirdApi from '../lib/api/sendBirdApi';
import useAdminStore from '../hooks/zustand/admin/useAdminStore';
import { useTranslation } from 'react-i18next';
const schemaMember = yup.object().shape({
  fullName: yup.string().required("Name is required."),
  phoneNumber: yup.string().required("Mobile number is required")
})

export default function ChatWidget() {
  const memberStore = useMemberListing();
  const adminStore = useAdminStore();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [currentChannelUrl, setCurrentChannelUrl] = useState();
  const [accessToken, setAccessToken] = useState("");
  const toggleChatWindow = () => {
    setIsOpen(!isOpen);
  };

  const {
    register,
    handleSubmit,
    formState : {errors, isSubmitting},
    reset, 
    setValue
  } = useForm({
    resolver: yupResolver(schemaMember)
  });

  const onSubmit = async (data) =>{
    try{
      console.log("data", data);
      data.firstName = data.fullName;
      const res = await memberStore.createAnonymousMember(data);
      console.log(res);
      if (res?.status === 200) {
        setIsOpenChat(true);
        setIsOpen(false);
        
        if (!res.data.isSendbirdUser){
          const payloadUser = {
            user_id: res.data.phoneNumber.toString(),
            nickname: res.data.fullName,
            profile_url: "https://sendbird.com/main/img/profiles/profile_05_512px.png",
            issue_access_token: true,
            metadata:{
              font_preference: "times new roman",
              font_color: "black"
            }
          }
          const respUser =  await SendBirdApi("users", payloadUser);
          res.data.isSendbirdUser = true;
          console.log("respUser", respUser.access_token);
          Object.assign(res.data, {sbAccessToken: respUser.access_token});
          res.data.sbAccessToken = respUser.access_token;
          console.log("res.data", res.data);
          setAccessToken(respUser.access_token);
        }
        else{
          setCurrentChannelUrl(res.data.sendBirdChannelId);
          setAccessToken(res.data.sbAccessToken);
          res.data.password = res.data.displayPassword;
        }
        const handler = await adminStore.getDataById(res.data.admin);
        console.log("handler", handler);
        if (handler){
          if (!handler?.isSendbirdUser){
            const payloadUser = {
              user_id: handler.adminId.toString(),
              nickname: handler.name,
              profile_url: "https://sendbird.com/main/img/profiles/profile_05_512px.png",
              issue_access_token: true,
              metadata:{
                font_preference: "times new roman",
                font_color: "black"
              }
            }
            const respUser =  await SendBirdApi("users", payloadUser);
            handler.sbAccessToken = respUser.access_token;
            handler.isSendbirdUser = true;
          }
        }
        if (res.data.isSendbirdUser && (res.data.sendBirdChannelId ?? "") === ""){
          const payload = {
            name: res.data.phoneNumber + "_" + handler.adminId ,
            channelUrl: "private_chat_" + res.data.phoneNumber,
            cover_url: "https://sendbird.com/main/img/cover/cover_08.jpg",
            is_distinct: true,
            user_ids: [res.data.phoneNumber.toString(), handler.adminId],
            operator_ids:[res.data.phoneNumber.toString() ,handler.adminId]
          }
          const respGroup = await SendBirdApi("group_channels", payload);
          if (respGroup?.error !== true){
            res.data.sendBirdChannelId = respGroup?.channel_url;
            handler.sendBirdChannelId = respGroup?.channelUrl;
          }
          const payloadMsg = {
            message_type: "MESG",
            user_id: handler.adminId,
            message: "Good day to you. I am your assistant. Ask my anything about the investment plan. ",
            mention_type: "users", 
            mentioned_user_ids: [res.data.phoneNumber]
          };
          await SendBirdApi(`group_channels/${respGroup?.channel_url}/messages`, payloadMsg); 

        }
        const payloadUpdMember = {
          member: res.data
        }
        
        payloadUpdMember.password = payloadUpdMember.displayPassword;
        console.log("payloadUpdMember", payloadUpdMember);
        await memberStore.updateMember(payloadUpdMember, res.data._id, null);
        handler.password = handler.displayPassword;
        await adminStore.updateData(handler._id, handler, null);
        setUserInfo(res.data);
        console.log("userInfo", userInfo);
        // await logStore.createData({
        //   adminId: authLogin.admin?.adminId,
        //   memberId: '',
        //   log: `${authLogin.admin?.adminId} create a new member profile ${data?.member?.phoneNumber} successfully`,
        //   payload: data,
        //   action: 'create',
        //   module: 'Member',
        //   table: 'users'
        // });
        enqueueSnackbar("Succes created new member", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else if (res?.status !== 200 || res?.response?.data?.error) {
        enqueueSnackbar(
          `An error occured. ${res?.response?.data?.error || null}`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    }
    catch(error){
      console.log(error);
    }
  
  }

  return (
    <>
      <style>
          {`
              .sendbird-icon-create {
                  visibility: hidden; /* Hide the send message icon */
                  pointer-events: none;
              }
          `}
      </style>
      {/* Chat icon */}
      <div className="fixed bottom-4 right-5 z-10 cursor-pointer">
        <img className="w-14" src="images/chat-icon.png" alt="Chat" onClick={toggleChatWindow} />
      </div>
      {isOpen && (
        <form onSubmit={handleSubmit(onSubmit)} >
        <div className="fixed bottom-20 right-20 w-2/3 md:w-1/2 h-1/2 bg-white border border-gray-300 z-10 overflow-y-auto">
          <div className="p-4">
            <h2 className="text-lg font-semibold">{ t("home.chatwidget.title") }</h2>
            <div className='py-3'>
              <div className='py-3'>
                <InputText 
                  label={ t("home.chatwidget.mobile") }
                  {...register("phoneNumber")}
                  error= {errors?.phoneNumber?.message}
                />
              </div>
              <div  className='py-3'>
                <InputText
                  label={ t("home.chatwidget.name") }
                  {...register("fullName")}
                  error= {errors?.fullName?.message}
                />
              </div>
              <div  className='py-3'>
                <Button 
                  type="submit"
                  className="w-max px-5 py-2 text-white text-sm font-semibold"
                  radius="full"
                  disabled={isSubmitting}
                >
                {!! isSubmitting ? t("home.chatwidget.PleaseWait") : t("home.chatwidget.submit")}
                </Button>
              </div>
              
            </div>
          </div>
          {/* <button className="absolute top-2 right-2 bg-gray-200 hover:bg-gray-300 px-2 py-1 text-sm rounded" onClick={toggleChatWindow}>Close</button> */}
        </div>
        </form>
      )}
      {!isOpen && isOpenChat && (
        <div className="fixed bottom-20 right-20 w-2/3 md:w-1/2 h-1/2 bg-white border border-gray-300 z-10 overflow-y-auto">
            <App
            replyType="THREAD"
            appId={process.env.REACT_APP_SENDBIRD_APP_ID}
            userId={userInfo?.phoneNumber}
            channelUrl={currentChannelUrl}
            //channelUrl='sendbird_group_channel_417843771_f859ef4c1fd04476693a9d651bbed419d76a4320'
            nickname={userInfo?.fullName}
            accessToken={accessToken}
            theme="light"
            isMentionEnabled={true}
            isMultipleFilesMessageEnabled={true}
            isMessageReceiptStatusEnabledOnChannelList={true}
            imageCompression={{
              compressionRate: 0.5,
              resizingWidth: 100,
              resizingHeight: '100px',
            }}
            disableAutoSelect={true}
            disableUserProfile={true}
            config={{ logLevel: 'all' }}
            breakpoint={/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)}
            showSearchIcon={false}
          />
              </div>
      )}
    </>
  );
}