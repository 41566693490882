import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle";
import ButtonLp from "../ButtonLp";
import { useTranslation } from "react-i18next";

const HomeSignUp = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto max-w-screen-xl px-5 mt-10 mb-20">
      <SectionTitle>{t("home.section4.end.title")}</SectionTitle>
      <div className="mx-auto my-10 lg:w-2/3">
        <Link to="/sign-up">
          <ButtonLp className="uppercase w-full overflow-hidden">
            {t("home.section4.end.button")}
          </ButtonLp>
        </Link>
      </div>
    </div>
  );
};

export default HomeSignUp;
