import Layout from '../components/Layout';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import { useEffect, useMemo } from 'react';
import usePageContent from '../hooks/zustand/usePageContent';
import { shallow } from 'zustand/shallow';
import { amlPolicy } from '../lib/api/cms-route.constant';
import { useTranslation } from 'react-i18next';

export default function AMLPolicy() {
  const { t, i18n } = useTranslation();
  const pageContent = usePageContent(
    (state) => ({ content: state.content, getData: state.getData }),
    shallow
  );

  const amlPolicyContent = useMemo(() => {
    return pageContent?.content[amlPolicy]?.content;
  }, [pageContent]);

  useEffect(() => {
    async function getData() {
      await pageContent.getData(amlPolicy, i18n.language);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Layout changeColorWhileScroll={true}>
      <div
        className='w-full h-full py-16'
        style={{ minHeight: 'calc(100vh - 15rem)' }}
      >
        <div className='relative w-full h-full z-10 max-w-5xl px-5 mx-auto'>
          <div className='grid grid-cols-1 gap-5'>
            <h3 className='text-black font-bold text-xl lg:text-3xl text-center'>
              { t("home.footer.AMLPolicy")}
            </h3>
            <ReactMarkdown
              children={amlPolicyContent ?? 'Loading data...'}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              className='prose prose-sm max-w-none prose-li:marker:text-black'
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
