import axios from "axios";

export default async function SendBirdApi  (functionName, payload, functionType = 'POST') {
    try{
        const sendbirdApi = process.env.REACT_APP_SENDBIRD_APP_ID;
        const urlPath = "https://api-"+sendbirdApi+".sendbird.com/v3/" + functionName;
        console.log("urlPath: " + urlPath);
        let resp; 
        if (functionType === 'POST'){
            resp = await axios.post(urlPath, payload, {
                headers:{
                    'Content-Type': 'application/json',
                    'Api-Token': `${process.env.REACT_APP_SENDBIRD_API_TOKEN}`
                }
            });
        }
        else {
            resp = await axios.get(urlPath, {
                headers:{
                    'Content-Type': 'application/json',
                    'Api-Token': `${process.env.REACT_APP_SENDBIRD_API_TOKEN}`
                }
            });
            console.log("SendBird Api Get",resp);
        }
        return resp.data;
    }
    catch(error){
        console.log("Error Sendbird API : " +  error);
        return error.response.data;
    }   
}

