import { create } from "zustand";
import { Api } from '../../lib/api/axios';

const newsInitiateState = () => ({
  news: {
    items: []
  }
});

const useNewsContent = create((set, get) => ({
  content: newsInitiateState(),
  getNews: async (language) => {
    try {
      const res = await Api.get(`/cms/news/${language ?? process.env.REACT_APP_DEFAULT_LANGUAGE}`);
      set((state) => ({
        content: {
          ...state.content,
          news: {
            ...state.content.news,
            items: res.data.items
          }
        }
      }));
      return res.data;
    }
    catch (error) {
      console.log("useNewsContent", error);
      return error.response?.data;
    }
  }
}));

export default useNewsContent;