import { Route } from "react-router-dom";
import { lazy } from "react";
import { AdminWithGuard } from "../middleware/route.guard";
// import AdminDashboard from "../pages/AdminDashboard/Dashboard";

// LAZY LOADING COMPONENT
const UserAccessAdmin = lazy(() =>
  import("../pages/AdminDashboard/UserAccess/UserAccess")
);
const RiskDisclosureAdmin = lazy(() =>
  import("../pages/AdminDashboard/PageContent/RiskDisclosure")
);
const ReturnPolicyAdmin = lazy(() =>
  import("../pages/AdminDashboard/PageContent/ReturnPolicy")
);
const AboutUsAdmin = lazy(() =>
  import("../pages/AdminDashboard/PageContent/AboutUs")
);
const AmlPolicyAdmin = lazy(() =>
  import("../pages/AdminDashboard/PageContent/AmlPolicy")
);
const PrivacyPolicyAdmin = lazy(() =>
  import("../pages/AdminDashboard/PageContent/PrivacyPolicy")
);
const HomeSliderAdmin = lazy(() => 
  import("../pages/AdminDashboard/HomeSliders/sliderList")  
);
// const UserList = lazy(() =>
//   import("../pages/AdminDashboard/Account/MemberList/MemberList")
// );
const UserListNew = lazy(() =>
  import("../pages/AdminDashboard/Account/MemberList")
);
const InvestmentNew = lazy(() =>
  import("../pages/AdminDashboard/Investment/MemberList")
);
const ListProduct = lazy(() =>
  import("../pages/AdminDashboard/Product/ProductList")
);
const ListBank = lazy(() => import("../pages/AdminDashboard/Bank/BankList"));
const ListAdmin = lazy(() => import("../pages/AdminDashboard/Admin/AdminList"));
const ListLog = lazy(() => import("../pages/AdminDashboard/AdminLog/LogList"));
const MemberPermissionControll = lazy(() =>
  import(
    "../pages/AdminDashboard/Account/MemberPermissionControll/MemberPermissionControll"
  )
);
const InvestmentPackageListing = lazy(() =>
  import(
    "../pages/AdminDashboard/Account/InvestmentPackageListing/InvestmentPackageListing"
  )
);
const InvestmentListing = lazy(() =>
  import("../pages/AdminDashboard/Account/InvestmentListing")
);
const HandlerAssign = lazy(() =>
  import("../pages/AdminDashboard/Account/HandleAssign/HandleAssignListing")
);
const WalletSummaryList = lazy(() =>
  import("../pages/AdminDashboard/Finance/WalletSummaryListing")
);
const WithdrawalApproval = lazy(() =>
  import(
    "../pages/AdminDashboard/Finance/WithdrawalApproval/WithdrawalApproval"
  )
);
const DepositWalletHistory = lazy(() =>
  import("../pages/AdminDashboard/Finance/DepositWalletHistory")
);
const ProfitLossWalletHistory = lazy(() =>
  import("../pages/AdminDashboard/Finance/ProfitLossWalletHistory")
);
const DepositWalletAdjustment = lazy(() =>
  import("../pages/AdminDashboard/Finance/DepositWalletAdjustment")
);
const PushMessages = lazy(() =>
  import("../pages/AdminDashboard/Message/PushMessage")
);
const NewMessage = lazy(() =>
  import("../pages/AdminDashboard/Message/NewMessage")
);
const InAppChat = lazy(() => import("../pages/AdminDashboard/Message/InAppChatChannel"));
const AdminInAppChat = lazy(() => import("../pages/AdminDashboard/Message/AdminInAppChat"));

// const AdminDashboardGuard = AdminWithGuard(AdminDashboard, "dashboard");
const RiskDisclosureGuard = AdminWithGuard(RiskDisclosureAdmin, "", true);
const ReturnPolicyGuard = AdminWithGuard(ReturnPolicyAdmin, "", true);
const AboutUsGuard = AdminWithGuard(AboutUsAdmin, "", true);
const AmlPolicyGuard = AdminWithGuard(AmlPolicyAdmin, "", true);
const PrivacyPolicyGuard = AdminWithGuard(PrivacyPolicyAdmin, "", true);
const AdminUserListGuard = AdminWithGuard(UserListNew, "memberListing");
const AdminInvestmentListGuard = AdminWithGuard(InvestmentNew, "investmentListing");
const ProductListGuard = AdminWithGuard(ListProduct, "cryptoListing");
const BankListGuard = AdminWithGuard(ListBank, "bankListing");
const HomeSliderGuard = AdminWithGuard(HomeSliderAdmin,"homeSlider");
const AdminListGuard = AdminWithGuard(ListAdmin, "adminListing");
const LogListGuard = AdminWithGuard(ListLog, "adminLog");
const AdminMemberPermissionControlGuard = AdminWithGuard(
  MemberPermissionControll
);
const AdminInvestPackageListingGuard = AdminWithGuard(
  InvestmentPackageListing,
  "",
  true
);
const AdminInvestmentListingGuard = AdminWithGuard(
  InvestmentListing,
  "investmentListing"
);
const AdminHandlerAssign = AdminWithGuard(HandlerAssign, "handlerAssign");
const AdminWalletSummaryListGuard = AdminWithGuard(
  WalletSummaryList,
  "walletSummary"
);
const AdminWithdrawalApprovalGuard = AdminWithGuard(
  WithdrawalApproval,
  "withdrawalApproval"
);
const AdminDepositWalletHistoryGuard = AdminWithGuard(DepositWalletHistory);
const AdminProfitLossWalletHistory = AdminWithGuard(ProfitLossWalletHistory, "profitLossHistory");
const AdminDepositWalletAdjustmentGuard = AdminWithGuard(
  DepositWalletAdjustment,
  "depositListing"
);
const UserAccessAdminGuard = AdminWithGuard(UserAccessAdmin, "adminAccess");
const PushMessageGuard = AdminWithGuard(PushMessages, "", true);
const NewMessageGuard = AdminWithGuard(NewMessage, "", true);
const InAppChatGuard = AdminWithGuard(InAppChat, "", true);
const AdminInAppChatGuard = AdminWithGuard(AdminInAppChat, "", true);

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // <Route element={<AdminDashboardGuard />} />,
  <Route index path="user-list" element={<AdminUserListGuard />} />,
  <Route index path="investment-list" element={<AdminInvestmentListGuard />} />,
  <Route path="product-list" element={<ProductListGuard />} />,
  <Route path="bank-list" element={<BankListGuard />} />,
  <Route path="admin-list" element={<AdminListGuard />} />,
  <Route path="log-list" element={<LogListGuard />} />,
  <Route
    path="member-permission"
    element={<AdminMemberPermissionControlGuard />}
  />,
  <Route
    path="investment-package-listing"
    element={<AdminInvestPackageListingGuard />}
  />,
  <Route path="investment-listing" element={<AdminInvestmentListingGuard />} />,
  <Route path="handler-listing" element={<AdminHandlerAssign />} />,
  <Route
    path="wallet-summary-listing"
    element={<AdminWalletSummaryListGuard />}
  />,
  <Route
    path="withdrawal-approval"
    element={<AdminWithdrawalApprovalGuard />}
  />,
  <Route
    path="deposit-wallet-history"
    element={<AdminDepositWalletHistoryGuard />}
  />,
  <Route
    path="profit-loss-wallet-history"
    element={<AdminProfitLossWalletHistory />}
  />,
  <Route
    path="deposit-wallet-adjustment"
    element={<AdminDepositWalletAdjustmentGuard />}
  />,
  <Route path="risk-disclosure-content" element={<RiskDisclosureGuard />} />,
  <Route path="return-policy-content" element={<ReturnPolicyGuard />} />,
  <Route path="about-us-content" element={<AboutUsGuard />} />,
  <Route path="aml-policy-content" element={<AmlPolicyGuard />} />,
  <Route path="privacy-policy-content" element={<PrivacyPolicyGuard />} />,
  <Route path="access" element={<UserAccessAdminGuard />} />,
  <Route path="push-messages" element={<PushMessageGuard />} />,
  <Route path="new-messages" element={<NewMessageGuard />} />,
  <Route path="home-slider" element={<HomeSliderGuard />} />,
  <Route path="in-app-chat/:id" element={<InAppChatGuard />} />,
  <Route path="admin-in-app-chat" element={<AdminInAppChatGuard />} />,
  <Route path="admin-in-app-chat/:id" element={<AdminInAppChatGuard />} />,
];
