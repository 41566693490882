import React from "react";

 export default function AwardsViewWidget({
  imgSrc
}) {
  return (
    <div className="overflow-hidden flex flex-auto justify-center">
      <img className="!h-48 " src={imgSrc} />
    </div>
  );
}