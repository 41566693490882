import { useMemo, useState, useEffect } from 'react';
import Paragraph from '../typography/Paragraph';
import toRinggit from '../../../lib/currencyFormatter';
import BadgePercentage from '../badge/badge.percentage';
import { ReactComponent as Polkadot } from '../icon/polkadot-logo.svg';
import { ReactComponent as Huobi } from '../icon/huobi-logo.svg';
import { ReactComponent as Ethereum } from '../icon/ethereum-logo.svg';
import { ReactComponent as Bitcoin } from '../icon/bitcoin-logo.svg';
import { ReactComponent as Ripple } from '../icon/xrp-logo.svg';
import { ReactComponent as Doge } from '../icon/doge-logo.svg';
import { ReactComponent as bnb } from '../icon/bnb-logo.svg';
import { ReactComponent as trx } from '../icon/trx-logo.svg';
import { ReactComponent as sol } from '../icon/sol-logo.svg';
import { ReactComponent as Usdt } from '../icon/usdt-logo.svg';
import { ReactComponent as Sp500 } from '../icon/ShareIndex/sp500-logo.svg';
import { ReactComponent as Dxy } from '../icon/ShareIndex/dxy-logo.svg';
import { ReactComponent as Vix } from '../icon/ShareIndex/vix-logo.svg';
import { ReactComponent as Dax } from '../icon/ShareIndex/dax-logo.svg';
import { ReactComponent as Hsi } from '../icon/ShareIndex/hsi-logo.svg';
import { ReactComponent as Cac40 } from '../icon/ShareIndex/cac40.svg';
import { ReactComponent as Nikkei } from '../icon/ShareIndex/nikkei-logo.svg';
import { ReactComponent as Ndx } from '../icon/ShareIndex/ndx-logo.svg';
import { useLocalStorage } from '@uidotdev/usehooks';

export default function TodayTrade({
  member,
  logo,
  profile,
  investAmount,
  profitLoss,
  changingTime,
  percentage,
  isProfit,
  isPlay,
  playDate,
  finishPlayDate
}) {
  const [running, setRunning] = useState(isPlay);
  const [prevProfitLoss, setPrevProfitLoss] = useLocalStorage(`${member} - ${profile}`, '');
  const [prevPercentage, setPrevPercentage] = useLocalStorage(
    `${member} - ${profile} percent`,
    ''
  );
  const [fakePercentage, setFakePercentage] = useState(prevPercentage);
  const [fakeProfitLoss, setFakeProfitLoss] = useState(prevProfitLoss);
  const [blink, setBlink] = useState(false);

  const initialValue = ((profitLoss / changingTime / 60) * 5).toFixed(2);

  const getDifferenceInFiveSeconds = (date1, date2) => {
    const millisecondsDifference = Math.abs(date2 - date1);
    const fiveSecondsDifference = Math.floor(millisecondsDifference / 5000); // 5000 milliseconds per 5 seconds
    return fiveSecondsDifference;
  };

  useEffect(() => {
    if (running && new Date() < new Date(finishPlayDate)) {
      const runningFiveSeconds = getDifferenceInFiveSeconds(new Date(playDate), new Date());
      setFakeProfitLoss(initialValue * runningFiveSeconds);
    }
  }, []);


  useEffect(() => {

    const intervalId = setInterval(() => {
      if (running && new Date() < new Date(finishPlayDate)) {
        const newPercentage =
          ((Number(fakeProfitLoss) + Number(initialValue)) / investAmount) * 100;
        setFakePercentage(Number(newPercentage.toFixed(2)));
        setFakeProfitLoss(Number(fakeProfitLoss.toFixed(2)) + Number(initialValue));
        setBlink(true);
        setTimeout(() => setBlink(false), 500);

        setPrevProfitLoss(Number(fakeProfitLoss.toFixed(2)) + Number(initialValue));
        setPrevPercentage(Number(newPercentage.toFixed(2)));
      } else {
        setPrevProfitLoss(0);
        setPrevPercentage(0);
        setRunning(false);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fakePercentage]);
  }, [fakeProfitLoss]);

  const GetLogo = useMemo(() => {
    switch (logo) {
      case 'BTC':
        return Bitcoin;
      case 'ETH':
        return Ethereum;
      case 'DOT':
        return Polkadot;
      case 'HT':
        return Huobi;
      case 'XRP':
        return Ripple;
      case 'DOGE':
        return Doge;
      case 'BNB':
        return bnb;
      case 'TRX':
        return trx;
      case 'SOL':
        return sol;
      case 'USDT':
        return Usdt;
      case 'SP500': 
        return Sp500;
      case 'DXY':
        return Dxy;
      case 'VIX':
        return Vix;
      case 'DAX':
        return Dax;
      case 'HSI':
        return  Hsi;
      case 'CAC40':
        return Cac40;
      case 'NIKKEI':
        return Nikkei;
      case 'NDX':
        return Ndx;
      default:
        return Bitcoin;
    }
  }, [logo]);

  return (
    <div className='h-max w-full flex items-center my-2.5'>
      <div className='w-1/5 h-full flex justify-center'>
        <GetLogo
          width={32}
          height={32}
          style={{
            backgroundColor: 'white',
            padding: '2px',
            borderRadius: '5px',
          }}
        />
      </div>
      <div className='flex w-full justify-between items-center'>
        <div>
          <Paragraph
            size='large'
            padding='none'
            color='white'
            className='font-semibold'
          >
            {profile}
          </Paragraph>
          <Paragraph
            size='medium'
            padding='none'
            color={
              isProfit && !running
                ? 'green'
                : !isProfit && !running
                  ? 'red'
                  : isProfit && running
                    ? 'green'
                    : !isProfit && running
                      ? 'red'
                      : null
            }
            className={`font-semibold ${blink && running ? '!text-white' : ''}`}
          >
            {isProfit && !running
              ? toRinggit(profitLoss)
              : !isProfit && !running
                ? `${toRinggit(profitLoss)}`
                : running
                  ? toRinggit(fakeProfitLoss)
                  : null}
          </Paragraph>
        </div>
        <div className='h-full flex items-end justify-center flex-col'>
          <BadgePercentage
            percentage={`${running ? fakePercentage : percentage}%`}
            isProfit={isProfit}
            isPlay={running}
          />
          <Paragraph
            padding='none'
            size='xs'
            className='py-1 my-2 px-3 bg-yellow-500 rounded-full flex items-center justify-center w-full max-w-max'
            color='black'
          >
            {running ? 'RUNNING' : 'END'}
          </Paragraph>
        </div>
      </div>
    </div>
  );
}
