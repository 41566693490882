import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid rgba(229, 231, 235, 1)', // Warna border
    borderRadius: '8px',
    padding: '4px',
    '&:focus': {
      borderColor: 'rgba(59, 130, 246, 1)',
      boxShadow: '0 0 0 3px rgba(59, 130, 246, 0.3)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'rgba(17, 24, 39, 1)',
    backgroundColor: 'white',
  }),
};

export default function RHFSelect({
  name,
  label,
  addon,
  listSelect,
  onChange,
  ...other
}) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <label className='mb-1 text-sm font-medium text-gray-900'>
            {label}
          </label>
          <Select
            {...field}
            // defaultValue='Select your bank'
            placeholder={t("home.signup.Select your bank")}
            options={listSelect}
            isSearchable={false}
            className='text-sm'
            styles={customStyles}
            value={listSelect.find((val) => val.label === field.label)}
            onChange={(selectedOption) => {
              field.onChange(selectedOption.label);
              if (onChange){
                onChange(selectedOption);
              }
            }}
            classNamePrefix='!bg-white !text-black '
          />
          {error?.message && (
            <p className='text-red-500 text-xs'>{error?.message}</p>
          )}
        </div>
      )}
    />
  );
}
