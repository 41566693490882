export const handleLoginResponse = (res, setError) => {
  if (res?.message === 'Invalid Phone number!') {
    setError('phoneNumber', { type: 'custom', message: res.message });
    return false;
  }
  if (res?.message === 'Invalid Password!') {
    setError('password', { type: 'custom', message: res.message });
    return false;
  }
  return true;
};

export const handleRegisterResponse = (
  res,
  setStep,
  onError,
  setError,
  navigate,
  language = 'en'
) => {
  if (res?.status === 201) {
    navigate(`/${language}/sign-in`, {
      state: {
        success: true,
      },
    });
  } else {
    setStep(1);
    onError(
      res?.response?.data?.error === 'Phone number already exists'
        ? 'This mobile number had been registered. Please contact your Broker.'
        : res?.response?.data?.error
    );
  }
  if (res?.response?.data?.error === 'Phone number already exists') {
    setError('phoneNumber', {
      type: 'custom',
      message: res?.response?.data?.error,
    });
  }
  if (res?.response?.data?.error === 'Email already exists') {
    setError('email', { type: 'custom', message: res?.response?.data?.error });
  }
  if (res?.response?.data?.error === 'Referral code is not exists') {
    setError('referralCode', {
      type: 'custom',
      message: res?.response?.data?.error,
    });
  }
};
